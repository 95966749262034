import React from 'react'

const BlogLayout = ({ title, image, children }) => {
  return (
    <div>
      <div
        className="pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-contain bg-right-top bg-no-repeat hidden md:flex md:flex-col items-center"
        style={{ backgroundImage: `url(${image})` }}
      >
        {children}
      </div>

      <div className="w-full mt-5 font-title self-center block md:hidden">
        <h1 className="text-3xl text-red-700 leading-tight uppercase px-6">
          {title}
        </h1>
        {children}
      </div>
    </div>
  );
}

export default BlogLayout;
