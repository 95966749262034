import React from "react";
import { Link } from "gatsby";

const BlogHeader = () => {
  return (
    <div className="w-full mt-8 lg:mt-64 px-6">
      <nav className="flex flex-wrap">
        <ul className="container md:border-t md:border-b flex font-title">
          <li className="md:border-l p-2 md:p-4 md:ml-4">
            <Link to="/blog">All</Link>
          </li>
          <li className="md:border-l p-2 md:p-4">
            <Link to="/blog/tag/Elixir">Elixir</Link>
          </li>
          <li className="md:border-l p-2 md:p-4">
            <Link to="/blog/tag/Phoenix">Phoenix</Link>
          </li>
          <li className="md:border-r md:border-l p-2 md:p-4">
            <Link to="/blog/tag/JavaScript">JavaScript</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default BlogHeader;
