import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import blog_image from "../images/blog-blocks-wallpaper-1591056-2.png";
import { Link, graphql } from "gatsby";
import BlogHeader from "../components/blog-header";
import moment from "moment";
import { startCase } from "lodash";
import Last10Js from "../components/last-10-js";
import Last10Elixir from "../components/last-10-elixir";
import UsefulLinks from "../components/useful-links";
import CategoriesList from "../components/available-cats-list";
import ArchiveLink from "../components/archive-link";
import BlogLayout from "../components/blog-layout";

const BlogExcerpt = ({ author, title, path, date, children }) => {
  const dateFormatted = moment.utc(date).format("MMMM DD, YYYY");
  return (
    <article className="bg-white border p-6 mb-4">
      <header>
        <h2 className="text-xl font-title text-red-700 hover:text-red-500">
          <Link to={path}>{title}</Link>
        </h2>
        <p className="mb-0 border-b text-gray-800 text-sm pb-2 mb-2">
          Posted on {dateFormatted} by {startCase(author)}
        </p>
      </header>
      <div>{children}</div>
    </article>
  );
};

const StyledLink = ({ to, children }) => {
  return (
    <Link to={to} className="text-red-700 text-2xl font-title">
      {children}
    </Link>
  );
};

const BlogPagination = ({ nPages, currentPage, tag }) => {
  return (
    <div className="w-full flex justify-between">
      {currentPage === 1 && <span>&nbsp;</span>}
      {currentPage > 1 && (
        <StyledLink
          to={`/blog/tag/${tag}${
            currentPage - 1 === 1 ? "" : "/" + (currentPage - 1)
          }`}
        >
          « Newer
        </StyledLink>
      )}
      {currentPage < nPages && (
        <StyledLink to={`/blog/tag/${tag}/${currentPage + 1}`}>
          Older »
        </StyledLink>
      )}
      {nPages === currentPage && <span>&nbsp;</span>}
    </div>
  );
};

const TagsList = (node) => {
  const {
    data,
    pageContext: { nPages, currentPage, tag },
  } = node;
  const blogItems = data.allMarkdownRemark.edges;
  return (
    <Layout headerClass="bg-white relative" bodyClass="container mx-auto md:px-10 lg:px-16">
      <SEO title={"Blog - " + tag} />
      <BlogLayout title={`Blog`} image={blog_image}>
        <div className="container md:mt-20 font-title self-start"></div>
        <BlogHeader />
        <div className="w-full flex flex-wrap pt-4 pb-4 bg-gray-50">
          <div className="w-full md:w-3/4 px-4 ">
            {blogItems.map(({ node }) => (
              <BlogExcerpt
                key={node.frontmatter.path}
                author={node.frontmatter.author}
                title={node.frontmatter.title}
                path={node.frontmatter.path}
                date={node.frontmatter.date}
              >
                {node.excerpt}
              </BlogExcerpt>
            ))}
            <BlogPagination
              nPages={nPages}
              currentPage={currentPage}
              tag={tag}
            ></BlogPagination>
          </div>
          <div className="w-full md:w-1/4 px-4">
            <div className="mb-6">
              <Last10Elixir />
            </div>
            <div className="mb-6">
              <Last10Js />
            </div>
            <div className="mb-6">
              <UsefulLinks />
            </div>
            <div className="mb-6">
              <CategoriesList />
            </div>
            <div className="mb-6">
              <ArchiveLink />
            </div>
          </div>
        </div>
      </BlogLayout>
    </Layout>
  );
};

export const query = graphql`
  query TagQuery($skip: Int!, $limit: Int!, $tag: String) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/blog/" }
        frontmatter: { tags: { in: [$tag] } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            author
            title
            path
            date
          }
          excerpt(pruneLength: 300)
        }
      }
    }
  }
`;

export default TagsList;
